import { createBrowserRouter } from "react-router-dom";
import Main from "../layout/main.layout";
import Home from "../pages/home";
import Blogs from "../pages/blogs";
import BrokenPage from "../pages/broken";
import About from "../pages/about";
import Technology from "../pages/technology";
import Security from "../pages/security";
import VSCode from "../pages/vscode";
import Pricing from "../pages/pricing";
import { Documents } from "../pages/documents";
import Support from "../pages/support";
import Snowflake from "../pages/snowflake";
import Videos from "../pages/videos";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/terms",
        element: <Documents docType="terms" />,
      },
      {
        path: "/privacy",
        element: <Documents docType="privacy" />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/technology",
        element: <Technology />,
      },
      {
        path: "/security",
        element: <Security />,
      },
      {
        path: "/snowflake-cost-savings",
        element: <Snowflake />,
      },
      {
        path: "/vscode",
        element: <VSCode />,
      },
      {
        path: "/support",
        element: <Support />,
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
      {
        path: "/videos",
        element: <Videos />,
      },
      {
        path: "/videos/:videoID",
        element: <Videos />,
      },
    ],
  },
  {
    path: "*",
    element: <BrokenPage />,
  },
]);

export default router;
