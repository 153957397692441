import { useEffect, useRef, useState } from "react";
import { tutorialData } from "./data";
import tutorialPlay from "../../assets/images/svgs/tutorial_play.svg";
import { useParams } from "react-router-dom";

const TagList = ({ selectedTag, tags, handleTagClick }) => {
  return (
    <div className="px-16 flex flex-wrap justify-center gap-x-3 gap-y-2">
      {tags.map((tag, index) => (
        <button
          className={`flex items-center gap-2 px-3 py-2 rounded text-[#082247] text-center font-lexend text-[16px] font-normal ${
            selectedTag === tag ? `bg-[#3F8CFF] text-white` : `bg-[#F6F5FA]`
          }`}
          key={index}
          onClick={() => handleTagClick(tag)}
        >
          {tag}
        </button>
      ))}
    </div>
  );
};

function get_tag_data_map(data) {
  const tag_data_map = { All: data };

  data.forEach((item) => {
    item.tags.forEach((tag) => {
      if (tag in tag_data_map) {
        tag_data_map[tag].push(item);
      } else {
        tag_data_map[tag] = [item];
      }
    });
  });

  return tag_data_map;
}

const InlineTagList = ({ tags }) => {
  return (
    <div className="mt-4 flex flex-wrap gap-2">
      {tags.map((tag, index) => (
        <div
          key={index}
          className="flex whitespace-nowrap items-center p-[10px] rounded-2xl bg-[#3F8CFF] bg-opacity-10 text-[#3F8CFF] font-lexend text-[14px] font-medium cursor-default"
        >
          {tag}
        </div>
      ))}
    </div>
  );
};

const Tutorial = ({
  title,
  description,
  youtubeEmbed,
  thumbnail,
  tags,
  index,
  hasRedirectionTriggered,
  setHasRedirectionTriggered,
  videoID = -1,
}) => {
  const isMobile = window.innerWidth < 768;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const handleVideoRun = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (hasRedirectionTriggered) return;
    if (Number(videoID) === index + 1) {
      setIsModalOpen(true);
    }
    setHasRedirectionTriggered(true);
  }, [videoID]);

  return (
    <div className={!isMobile && "max-w-[510px] h-[634px]"}>
      <div className="relative cursor-pointer group" onClick={handleVideoRun}>
        <img
          src={tutorialPlay}
          alt="tutorial play"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        />
        {thumbnail}
      </div>
      <div className="flex flex-col justify-between">
        <div className="py-8 text-[#082247] font-lexend text-[24px] font-medium">
          {title}
        </div>
        <div className="text-[#365178] font-lexend text-[16px] font-normal">
          {description}
        </div>
        <InlineTagList tags={tags} />
      </div>

      {isModalOpen && (
        <div className="fixed w-screen h-screen inset-0 flex items-center justify-center bg-black bg-opacity-40 z-[1001]">
          <div ref={modalRef} className="relative" onClick={handleCloseModal}>
            {youtubeEmbed}
          </div>
        </div>
      )}
    </div>
  );
};

const Tutorials = () => {
  const [hasRedirectionTriggered, setHasRedirectionTriggered] = useState(false);
  const { videoID } = useParams();
  const topRef = useRef(null);

  const tag_data_map = get_tag_data_map(tutorialData);

  const [selectedTag, setSelectedTag] = useState("All");

  const itemsPerPage = 10;
  const totalPages = Math.ceil(tag_data_map[selectedTag].length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
    setCurrentPage(1);
  };

  const handlePageClick = (e) => {
    topRef.current?.scrollIntoView({ behavior: "smooth" });
    setCurrentPage(Number(e.target.textContent));
  };

  if (!tag_data_map) return;

  return (
    <div className="bg-white flex flex-col justify-center items-center">
      <div ref={topRef} />
      <div className="bg-white py-[60px] px-8 md:px-0 md:max-w-[1070px]">
        <div className="text-center">
          <div className="m-auto">
            <h3 className="text-[#082247] text-center font-lexend text-[36px] font-semibold">
              Explore popular use cases to
            </h3>
            <h3 className="text-[#082247] text-center font-lexend text-[36px] font-semibold">
              accelerate your development
            </h3>
          </div>
        </div>
        <div className="mt-10">
          <TagList
            selectedTag={selectedTag}
            tags={Object.keys(tag_data_map)}
            handleTagClick={handleTagClick}
          />
        </div>
        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-[30px] place-items-center">
          {[...tag_data_map[selectedTag]]
            .reverse()
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((item, index) => (
              <Tutorial
                key={index}
                title={item.title}
                description={item.description}
                youtubeEmbed={item.youtubeEmbed}
                thumbnail={item.thumbnail}
                tags={item.tags}
                index={index}
                hasRedirectionTriggered={hasRedirectionTriggered}
                setHasRedirectionTriggered={setHasRedirectionTriggered}
              />
            ))}
        </div>
        <div className="flex justify-center mt-5">
          {Array(totalPages)
            .fill(0)
            .map((_, i) => (
              <button
                key={i}
                className={`px-3 py-2 ${
                  currentPage === i + 1 ? "bg-gray-200" : ""
                }`}
                onClick={handlePageClick}
              >
                {i + 1}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Tutorials;
