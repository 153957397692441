import React from "react";

import add1 from "../../assets/images/svgs/add4.svg";
import add2 from "../../assets/images/svgs/add2.svg";
import add3 from "../../assets/images/svgs/add3.svg";

const SecurityAdditional = () => {
  const data = [
    {
      title: "No customer data used",
      text: "DataPilot only uses metadata. No customer data is retrieved or stored in the SaaS instance.",
      image: add1,
    },
    {
      title: "Multi-tenant Architecture",
      text: "Every product instance gets its own unique URL and it’s completely isolated from other instances.",
      image: add2,
    },
    {
      title: "Encryption in transit and at rest",
      text: "Customer metadata is encrypted in transit and at rest through services offered by our cloud providers",
      image: add3,
    },
  ];

  return (
    <div className="px-6 py-[60px] md:pl-[195px] md:pr-[80px] md:py-[122px] bg-white md:h-[572px] flex-col text-center">
      <div>
        <div className="font-semibold text-[#082247] text-[32px] md:text-[42px] leading-normal">
          Additional Security Measures
        </div>
        <div className="mt-12 flex flex-col md:flex-row justify-between gap-[30px]">
          {data.map((d, i) => {
            return (
              <div
                key={i}
                className="flex flex-col items-center md:items-start md:text-start"
              >
                <div className="w-[68px] h-[68px] rounded-[12px] bg-[#F5F7F9] p-4">
                  <img src={d.image} alt="" />
                </div>
                <div className="w-3/4 md:w-full mt-5 font-semibold text-[20px] text-[#082247]">
                  {d.title}
                </div>
                <p className="mt-4 font-normal text-[#365178] text-[16px]">
                  {d.text}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SecurityAdditional;
