import React from "react";

import culture from "../../assets/images/jpgs/culture.jpg";

const PhilosophyCulture = () => {
  const data = [
    {
      id: 1,
      title: "Customer-centric approach",
      desc: "We prioritize the needs and requirements of its customers.",
    },
    {
      id: 1,
      title: "Embrace innovation",
      desc: "We are open to new ideas and technologies.",
    },
    {
      id: 1,
      title: "Maintain transparency",
      desc: "We are transparent about our operations and processes.",
    },
  ];
  return (
    <div className="philosophy-culture md:py-[120px] py-[50px] relative bg-[#F5F7F9]">
      <div className="custom-container">
        <div className="lg:flex justify-between items-center">
          <div className="lg:w-[45%] w-full md:pr-14">
            <p className="text-xl font-[600] text-[#247EFE]">
              Philosophy & Culture
            </p>
            <h2 className="lg:text-[42px] text-[24px] font-[600] text-[#082247] lh-2 my-3">
              Help the world make magic with data
            </h2>

            {data.map((item, index) => (
              <div
                key={index}
                className="flex flex-col items-start justify-center rounded-lg cursor-pointer hover:bg-white p-[24px]"
              >
                <div className="flex justify-start items-center">
                  <div className="line bg-[#111013] w-[10px] h-[2px] mr-3"></div>
                  <h3 className="font-semibold text-lg text-[#082247]">
                    {item.title}
                  </h3>
                </div>
                <p className="text-[#8390A3] mt-[15px]">{item.desc}</p>
              </div>
            ))}

            <div className="h-[2px] w-full bg-[#E7E8EA] mt-[20px]"></div>
          </div>
          <div className="lg:w-[55%] w-full">
            <div></div>
            <img src={culture} className="w-full" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhilosophyCulture;
