import arrowDownRight from "../../assets/images/svgs/arrow-down-right.svg";
import arrowRightDown from "../../assets/images/svgs/arrow-right-down.svg";
import lineDownRight from "../../assets/images/svgs/line-down-right.svg";
import lineRightDown from "../../assets/images/svgs/line-right-down.svg";
import lineLeftDown from "../../assets/images/svgs/line-left-down.svg";
import lineDownLeft from "../../assets/images/svgs/line-down-left.svg";
import lineBlue from "../../assets/images/svgs/line-blue.svg";
import arrowDownBlue from "../../assets/images/svgs/arrow-down-blue.svg";

import dataLogo from "../../assets/images/svgs/data/data.svg";
import dbtLogo from "../../assets/images/svgs/data/dbt.svg";
import snowflakeLogo from "../../assets/images/svgs/data/snowflake.svg";
import sqlLogo from "../../assets/images/svgs/data/sql.svg";
import { useEffect, useState } from "react";

const logos = [dataLogo, dbtLogo, snowflakeLogo, sqlLogo];

const HomePage1 = () => {
  const [leftWidth, setLeftWidth] = useState(
    window.innerWidth / 2 -
      (window.innerWidth > 2200 ? 375 : window.innerWidth > 1500 ? 200 : 0)
  );
  useEffect(() => {
    window.addEventListener("resize", () => {
      setLeftWidth(
        window.innerWidth / 2 -
          (window.innerWidth > 2200 ? 400 : window.innerWidth > 1500 ? 200 : 0)
      );
    });
  }, []);
  const isMobile = window.innerWidth <= 900;
  const handleWorkflowClick = (workflowLink) => () => {
    window.location.hash = workflowLink;
  };
  const [currentSVG, setCurrentSVG] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSVG((prevSVG) => (prevSVG + 1) % logos.length);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  if (isMobile)
    return (
      <div className="homepage-1 d-flex items-center mb-5">
        <div className="flex justify-center text-center">
          <div
            className="developement-small-box developement-small-box-top"
            onClick={handleWorkflowClick("accelerate-with-ai")}
          >
            Accelerate with AI
          </div>
          <img src={lineBlue} alt={lineBlue} />
          <div
            className="developement-small-box developement-small-box-top"
            onClick={handleWorkflowClick("prevent-issues")}
          >
            Prevent Issues Earlier
          </div>
        </div>
        <div className="dev-top-line-2">
          <img src={lineDownRight} alt={lineDownRight} />
          <div
            className="developement-small-box developement-small-box-top dev-mobile inline-flex"
            onClick={handleWorkflowClick("best-practices")}
          >
            Embed Best Practices
          </div>
          <img src={lineDownLeft} alt={lineDownLeft} />
        </div>
        <div className="flex justify-center">
          <img src={arrowDownBlue} alt={arrowDownBlue} />
        </div>
        <div className="developement-box">
          <div className="developement-text font-semibold ">
            <div className="developement-first-header font-semibold">
              Do{" "}
              <span>
                <img
                  className="developement-logos"
                  src={logos[currentSVG]}
                  alt="logos"
                  key={currentSVG}
                />
              </span>
            </div>
            <h1>Development Right</h1>
            <h1>
              with{" "}
              <span className="font-semibold text-[#247EFE]">DataPilot</span>
            </h1>
          </div>
        </div>

        <div className="arrow-down flex justify-center">
          <img src={arrowDownBlue} alt={arrowDownBlue} />
        </div>

        <div className="dev-top-line-3">
          <img src={lineLeftDown} alt={lineLeftDown} />
          <div className="developement-small-box dev-mobile-2 inline-flex">
            AI-enhanced Collaboration
          </div>
          <img src={lineRightDown} alt={lineRightDown} />
        </div>
        <div className="flex justify-center text-center">
          <div className="developement-small-box">30% Cost Reduction</div>
          <img src={lineBlue} alt={lineBlue} />
          <div className="developement-small-box">2X Faster Development</div>
        </div>
      </div>
    );
  return (
    <div className="homepage-1">
      <div className="feature-boxes feature-boxes-top">
        <div className="small-boxes small-boxes-top">
          <div
            className="developement-small-box developement-small-box-top"
            onClick={handleWorkflowClick("accelerate-with-ai")}
          >
            Accelerate with AI
          </div>
          <img src={lineBlue} alt={lineBlue} />
          <div
            className="developement-small-box developement-small-box-top"
            onClick={handleWorkflowClick("prevent-issues")}
          >
            Prevent Issues Earlier
          </div>
          <img src={lineBlue} alt={lineBlue} />
          <div
            className="developement-small-box developement-small-box-top"
            onClick={handleWorkflowClick("best-practices")}
          >
            Embed Best Practices
          </div>
        </div>
        <img
          className="smallboxes-arrow"
          src={arrowDownRight}
          alt={arrowDownRight}
        />
      </div>
      <div className="developement-box">
        <div className="developement-text font-semibold ">
          <div className="developement-first-header font-semibold">
            Do{" "}
            <span>
              <img
                className="developement-logos"
                src={logos[currentSVG]}
                alt="logos"
                key={currentSVG}
              />
            </span>
          </div>
          <h1>Development Right</h1>
          <h1>
            with <span className="font-semibold text-[#247EFE]">DataPilot</span>
          </h1>
        </div>
      </div>

      <div
        className="feature-boxes feature-boxes-bottom"
        style={{
          marginLeft: `${leftWidth}px`,
        }}
      >
        <img src={arrowRightDown} alt={arrowRightDown} />
        <div className="small-boxes small-boxes-bottom">
          <div className="developement-small-box">30% Cost Reduction</div>
          <img src={lineBlue} alt={lineBlue} />
          <div className="developement-small-box">2X Faster Development</div>
          <img src={lineBlue} alt={lineBlue} />
          <div className="developement-small-box">
            AI-enhanced Collaboration
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage1;
