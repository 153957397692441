const VSCODE_EXTENSION_LINK =
  "https://marketplace.visualstudio.com/items?itemName=innoverio.vscode-dbt-power-user";
const REGISTER_LINK = "https://app.myaltimate.com/register";
const GET_STARTED_GOOGLE_FORM = "https://forms.gle/qq99vPD5h8JpF6oN9";

const handleGithubLinkClick = () => {
  if (window.gtag) {
    window.gtag("config", "G-B7LCCXF90E");
    window.gtag("event", "conversion", {
      send_to: "AW-10951864387/Gj_uCJzPgc8YEMPgoOYo",
    });
  }
};

export {
  VSCODE_EXTENSION_LINK,
  REGISTER_LINK,
  GET_STARTED_GOOGLE_FORM,
  handleGithubLinkClick,
};
