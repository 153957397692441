import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const ProductTextContent = ({ title, content, rev, workflowLink }) => {
  const isMobile = window.innerWidth <= 900;
  const options = Object.keys(content);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  return (
    <div
      id={workflowLink}
      className={`product-workflow ${
        rev ? "product-workflow-reverse" : "product-workflow-straight"
      }`}
    >
      <div
        className={`product-text-content ${
          rev && "product-text-content-reverse"
        }`}
      >
        <div className="md:text-3xl text-xl font-semibold text-[#247EFE] pb-[16px] md:pb-[20px]">
          {title}
        </div>
        <div className="product-options">
          {options.map((option, i) => (
            <div
              key={i}
              className={`product-option ${
                selectedOption === option
                  ? "selected-option"
                  : `${rev ? "unselected-option-2" : "unselected-option-1"}`
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedOption(option);
              }}
            >
              {option}
            </div>
          ))}
        </div>
        <div
          className={`${
            isMobile ? "px-[48px] text-base" : "text-2xl mb-[24px]"
          }`}
        >
          {content[selectedOption].text}
        </div>
        {!isMobile && (
          <Link to={content[selectedOption].button.link} target="_blank">
            <button className=" hover:bg-[#3677D9] bg-[#3F8CFF] px-[30px] py-[12px] text-white rounded-[12px] text-[16px] font-medium">
              {content[selectedOption].button.text}
            </button>
          </Link>
        )}
      </div>
      <div
        className={`flex ${rev ? "flex-row-reverse" : "flex-row"} ${
          isMobile
            ? "h-[244px] w-[370px] justify-center"
            : "w-[44%] rounded-[12px] justify-start"
        }`}
      >
        <img
          className={isMobile ? "h-[244px] " : "w-full rounded-[12px]"}
          src={content[selectedOption].gif}
          alt={content[selectedOption]}
        />
      </div>
      {isMobile && (
        <Link to={content[selectedOption].button.link}>
          <button className=" hover:bg-[#3677D9] bg-[#3F8CFF] px-[30px] py-[12px] text-white rounded-[12px] text-[16px] font-medium">
            {content[selectedOption].button.text}
          </button>
        </Link>
      )}
    </div>
  );
};

const ProductWorkflow = ({ workflowData }) => {
  const workflowLinking = useRef();
  useEffect(() => {
    workflowLinking.current = new MutationObserver(() => {
      const hash = window.location.hash.substring(1);
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView();
        workflowLinking.current.disconnect();
      }
    });
    workflowLinking.current.observe(document, {
      childList: true,
      subtree: true,
    });
    return () => {
      workflowLinking.current.disconnect();
    };
  }, []);

  return (
    <div className="product-workflows">
      {workflowData.map((workflow, i) => (
        <ProductTextContent key={i} {...workflow} />
      ))}
    </div>
  );
};

export default ProductWorkflow;
