import React, { useEffect, useState } from "react";
// import HeroHome from "../../components/hero/hero-home";
import Counter from "../../components/counter";
import GetStarted from "../../components/getStarted";
import Testimonial from "../../components/testimonial";
// import Workflows from "../../components/workflows";
import PrivacyAndSecurity from "../../components/PrivacyAndSecurity";
import Integrated from "../../components/Integrated";
import Reviews from "../../components/reviews";
import { useRef, createRef } from "react";

import accelerateWithAI_1_1 from "../../assets/images/gifs/home_gifs/1-1-accelerate-with-ai.gif";
import accelerateWithAI_1_2 from "../../assets/images/gifs/home_gifs/1-2-accelerate-with-ai.gif";
import preventIssuesEarlier2_1 from "../../assets/images/gifs/home_gifs/2-1-prevent-issues-earlier.gif";
import preventIssuesEarlier2_2 from "../../assets/images/gifs/home_gifs/2-2-prevent-issues-earlier.gif";
import bestPractices3_1 from "../../assets/images/gifs/home_gifs/3-1-best-practices.gif";
import bestPractices3_2 from "../../assets/images/gifs/home_gifs/3-2-best-practices-1.gif";
import arrowRight from "../../assets/images/svgs/arrow-right.svg";

import { useSearchParams } from "react-router-dom";
import { PartnerCompanies, UserCompanies } from "../../components/companies";
import HomePage1 from "../../components/hero/home-1";
import Header from "../../components/header";
import ProductWorkflow from "../../components/workflows/productFlows";
import {
  // DatapilotActions,
  DatapilotTechStack,
} from "../../components/dataPilot";

const workflowData = [
  {
    title: "Accelerate with AI",
    workflowLink: "accelerate-with-ai",
    rev: false,
    content: {
      dbt: {
        text: (
          <div>
            <div className="font-[400]">
              Start conversation to get assistance to
            </div>
            <div className="workflow-2-text-1 font-[400]">
              Understand{" "}
              <span>
                <img
                  className="workflow-2-text-1-arrow"
                  src={arrowRight}
                  alt="arrow-right"
                />
              </span>
              Develop{" "}
              <span>
                <img
                  className="workflow-2-text-1-arrow"
                  src={arrowRight}
                  alt="arrow-right"
                />
              </span>
              Test{" "}
              <span>
                <img
                  className="workflow-2-text-1-arrow"
                  src={arrowRight}
                  alt="arrow-right"
                />
              </span>
              Document
            </div>
          </div>
        ),
        gif: accelerateWithAI_1_1,
        button: {
          text: "Explore Power User for dbt",
          link: "https://marketplace.visualstudio.com/items?itemName=innoverio.vscode-dbt-power-user",
        },
      },
      Snowflake: {
        text: (
          <div>
            <div className="font-[400]">
              AI agents to explain queries, write documentation and automate
              admin work
            </div>
          </div>
        ),
        gif: accelerateWithAI_1_2,
        button: {
          text: "Start for Free",
          link: "https://app.myaltimate.com/register",
        },
      },
    },
  },
  {
    title: "Prevent Issues Earlier",
    workflowLink: "prevent-issues",
    rev: true,
    content: {
      dbt: {
        text: (
          <div>
            <div className="font-[400]">
              Collaborate with business teams during development to review data,
              documentation and column lineage
            </div>
          </div>
        ),
        gif: preventIssuesEarlier2_1,
        button: {
          text: "Explore Power User for dbt",
          link: "https://marketplace.visualstudio.com/items?itemName=innoverio.vscode-dbt-power-user",
        },
      },
      Snowflake: {
        text: (
          <div>
            <div className="font-[400]">
              Get cost and performance insights plus recommendations for
              queries, warehouses, tables, and users
            </div>
          </div>
        ),
        gif: preventIssuesEarlier2_2,
        button: {
          text: "Message Us for a Recorded Demo",
          link: "https://app.myaltimate.com/contactus",
        },
      },
    },
  },
  {
    title: "Embed Best Practices",
    workflowLink: "best-practices",
    rev: false,
    content: {
      dbt: {
        text: (
          <div>
            <div className="font-[400]">
              Embed best practices and org-wide policies in IDE, Git, CI/CD to
              turn everyone into experts
            </div>
          </div>
        ),
        gif: bestPractices3_1,
        button: {
          text: "Install Python Package",
          link: "https://github.com/AltimateAI/datapilot-cli",
        },
      },
      Snowflake: {
        text: (
          <div>
            <div className="font-[400]">
              Get Cost and Performance insights in IDE and utilize best queries
              in organization via query store
            </div>
          </div>
        ),
        gif: bestPractices3_2,
        button: {
          text: "Message Us for a Recorded Demo",
          link: "https://app.myaltimate.com/contactus",
        },
      },
    },
  },
];

const Home = () => {
  const isMobile = window.innerWidth <= 900;
  const workflowsRefs = useRef(
    Array(workflowData.length)
      .fill(null)
      .map(() => createRef()),
  );

  const [scrollData, setScrollData] = useState(null);

  const handleCardClick = (parentIndex, childIndex) => {
    setScrollData([parentIndex, childIndex]);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const scrollTo = searchParams.get("scroll");

  useEffect(() => {
    if (scrollTo === "1" || scrollTo === "2") {
      const element = workflowsRefs.current[scrollTo].current;
      const offset = window.innerWidth <= 768 ? 10 : 40;

      setTimeout(() => {
        window.scrollTo({
          top: element.offsetTop - offset,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [scrollTo, workflowsRefs]);

  return (
    <div>
      <Header />
      <HomePage1 workflowsRefs={workflowsRefs} />
      <Counter />
      <UserCompanies />
      <ProductWorkflow workflowData={workflowData} />
      <DatapilotTechStack />
      <PartnerCompanies isMobile={isMobile} />
      <PrivacyAndSecurity />
      {/* <DatapilotActions /> */}
      <Testimonial />
      <Reviews />
    </div>
  );
};

export default Home;
