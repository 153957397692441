import React, { useState } from "react";
import { Link } from "react-router-dom";
import check from "../../dbt_assets/images/check.svg";
import { LuDownload } from "react-icons/lu";

import tm from "../../dbt_assets/images/tm.svg";
import Header from "../header";
import { useQuery } from "react-query";
import {
  defaultDownloadsCount,
  formatNumber,
  processVSCodeDownloadsCount,
} from "../utils/utils";
import { vsCodeDBTDownloads } from "../utils/backendHelper";

const VSCODE_DBT_DOWNLOADS = "VSCODE-DBT-DOWNLOADS";

const dbtDevPoints = [
  {
    setSlide: 0,
    scrollHref: "col1",
    title: "Generate dbt models and translate SQL",
  },
  {
    setSlide: 1,
    scrollHref: "col2",
    title: "Quickly navigate and smart complete dbt code.",
  },
  {
    setSlide: 2,
    scrollHref: "col3",
    title: "Debug by generating tests, column lineage, query explanations",
  },
  {
    setSlide: 3,
    scrollHref: "col4",
    title: "Collaborate via docs generation, share query results.",
  },
  {
    setSlide: 4,
    scrollHref: "col5",
    title: "Follow best practices via project health checks.",
  },
];

const DBTHeader = () => {
  const [menuClass, setMenuClass] = React.useState("");
  const isMobile = window.innerWidth <= 900;

  const toggleMenu = () =>
    setMenuClass((_menu) => (_menu === "" ? "active" : ""));

  const testimonials = [
    {
      id: 1,
      items: [
        {
          id: 1,
          name: "Guilherme da Silva",
          feedback: (
            <p className="text-[#333]">
              Improve a lot of the{" "}
              <b className="text-[#FF754C]">productivity</b>
            </p>
          ),
        },
        {
          id: 2,
          name: "Michael Weikman",
          feedback: (
            <p className="text-[#333]">
              <b className="text-[#FF754C]">Query / complie preview</b> are
              great QOL features that I use often
            </p>
          ),
        },
      ],
    },

    {
      id: 2,
      items: [
        {
          id: 1,
          name: "Daniel Ladd",
          feedback: (
            <p className="text-[#333]">
              <b className="text-[#FF754C]">
                Viewing upstream/downstream models
              </b>{" "}
              provides a lot of context
            </p>
          ),
        },
        {
          id: 2,
          name: "Anthony Alvarez",
          feedback: (
            <p className="text-[#333]">
              Love the functionality to drill into model references
            </p>
          ),
        },
      ],
    },
    {
      id: 3,
      items: [
        {
          id: 1,
          name: "Graham Carman",
          feedback: (
            <p className="text-[#333]">
              <b className="text-[#FF754C]">Run parent / child models</b> with a
              click of button
            </p>
          ),
        },
        {
          id: 2,
          name: "Boris Gracevic",
          feedback: (
            <p className="text-[#333]">
              <b className="text-[#FF754C]">Best tool</b> I have used for vs
              code
            </p>
          ),
        },
      ],
    },
    {
      id: 4,
      items: [
        {
          id: 1,
          name: "Juan Ramos",
          feedback: (
            <p className="text-[#333]">
              Makes dbt development so much{" "}
              <b className="text-[#FF754C]">easier</b>
            </p>
          ),
        },
        {
          id: 2,
          name: "Jacon Matson",
          feedback: (
            <p className="text-[#333]">
              Lots of <b className="text-[#FF754C]">great QOL features</b>
            </p>
          ),
        },
      ],
    },
    {
      id: 5,
      items: [
        {
          id: 1,
          name: "Daniel Ladd",
          feedback: (
            <p className="text-[#333]">
              <b className="text-[#FF754C]">
                Viewing upstream/downstream models
              </b>{" "}
              provides a lot of context
            </p>
          ),
        },
        {
          id: 2,
          name: "Graham Carman",
          feedback: (
            <p className="text-[#333]">
              <b className="text-[#FF754C]">Run parent / child models</b> with a
              click of button
            </p>
          ),
        },
      ],
    },
  ];

  const [activeSlide, setActiveSlide] = React.useState(0);
  const [dbtInstalls, setDBTInstalls] = useState(
    formatNumber(defaultDownloadsCount * 1000),
  );
  useQuery(VSCODE_DBT_DOWNLOADS, vsCodeDBTDownloads, {
    onSuccess: (data) => {
      setDBTInstalls(formatNumber(processVSCodeDownloadsCount(data)));
    },
  });

  return (
    <div className="dbt-hero header">
      <div className="custom-container">
        <Header />
        <div className={`menu-bg ${menuClass}`} onClick={toggleMenu}></div>

        {/* hero */}
        <section className="hero md:pt-[80px] pt-8 md:pb-[100px]">
          <div className="flex mt-[100px] md:flex-row flex-col justify-between items-center">
            <div className="w-full text-center md:text-left md:w-[50%] mb-8">
              <h1 className="text-[#082247] text-[48px] font-[600]">
                Do “dbt” development right in IDE, Git, CI/CD
              </h1>
              {isMobile && (
                <div className="w-full pl-5">
                  <div className="flex flex-col items-left text-left  gap-1 links mt-[36px]">
                    {dbtDevPoints.map((point, index) => (
                      <a
                        onMouseEnter={() => setActiveSlide(point.setSlide)}
                        href={`#${point.scrollHref}`}
                        key={index}
                        className={`text-[#5E7493] hover:text-[#3F8CFF] inline-flex justify-start items-center rounded-md ${
                          activeSlide === 0 && "active"
                        }`}
                      >
                        <div className="hover:rounded-[10px] hover:bg-[#3F8CFF1A] flex gap-2 justify-between px-2 py-2">
                          <img src={check} className="check" alt="" />{" "}
                          <span className="">{point.title}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}
              <div className="flex flex-col md:flex-row md:gap-4 md:mt-[30px] gap-4 mt-[40px] mb-[48px]">
                <div className="md:text-left text-center">
                  <Link to="https://marketplace.visualstudio.com/items?itemName=innoverio.vscode-dbt-power-user">
                    <button className="hover:bg-[#3677D9] bg-[#3F8CFF] px-[20px] py-[12px] text-white rounded-[10px] md:mb-0 md:rounded-md text-[16px] font-medium">
                      Power User for dbt
                    </button>
                  </Link>
                </div>
                <div className="md:text-left text-center">
                  <Link
                    to="https://datapilot.readthedocs.io/en/latest/readme.html#installation"
                    target="_blank"
                  >
                    <button className="hover:bg-[#3F8CFF] hover:text-white bg-white px-[20px] py-[12px] text-[#3F8CFF] border border-[#3F8CFF] rounded-[10px] md:mb-0 md:rounded-md text-[16px] font-medium">
                      Python package
                    </button>
                  </Link>
                </div>
              </div>
              {!isMobile && (
                <div className="w-full">
                  <div className="flex flex-col items-left text-left  gap-1 links mt-[36px]">
                    {dbtDevPoints.map((point, index) => (
                      <a
                        onMouseEnter={() => setActiveSlide(point.setSlide)}
                        href={`#${point.scrollHref}`}
                        key={index}
                        className={`text-[#5E7493] hover:text-[#3F8CFF] inline-flex justify-start items-center rounded-md ${
                          activeSlide === 0 && "active"
                        }`}
                      >
                        <div className="hover:rounded-[10px] hover:bg-[#3F8CFF1A] flex gap-2 justify-between px-2 py-2">
                          <img src={check} className="check" alt="" />{" "}
                          <span className="">{point.title}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full mb-16 md:mb-0 md:w-[50%]">
              <div className="testimonial-card">
                <div className="card relative flex flex-col items-center">
                  <div
                    className="w-[85%] h-[262px] md:w-[85%] md:h-[496px] bg-[#082247] rounded-[20px] backdrop-blur-[50px]"
                    alt=""
                  ></div>

                  <img
                    src={tm}
                    className="w-[100px] h-[128px] top-[10%] md:w-[160px] md:h-[204px] right-0 absolute md:-right-[50px] md:top-[30%] -translate-y-[50%] card-info"
                    alt=""
                  />

                  <div className="testimonials w-[70%] md:w-[90%] flex flex-col gap-[10px] md:gap-[30px] md:h-full absolute top-[14%] md:top-[24%] -left-[10px]">
                    {testimonials[activeSlide].items.map((item, index) => (
                      <div
                        key={index}
                        className={`${
                          index === 1 ? "ml-auto" : "mr-auto"
                        }   max-w-[70%] holder`}
                      >
                        <div className="max-h-[90px] md:max-h-full flex justify-start items-start bg-white rounded-[12px] shadow p-[12px] gap-[12px]">
                          <div className="avatar text-[26px] min-w-[80px] md:min-w-[94px] h-[70px] md:h-[94px] bg-[#04BEFE33] rounded-[12px] flex justify-center items-center">
                            {/* first laters of each word of the name */}
                            {item.name &&
                              item.name.split(" ").map((word, index) => (
                                <span
                                  className="uppercase text-4xl font-bold text-[#9D9D9D]"
                                  key={index}
                                >
                                  {word[0]}
                                </span>
                              ))}
                          </div>

                          <div className="feedback text-[10px] md:text-[16px]">
                            <p className="text-black font-bold text-[12px] md:text-[18px] md:mb-1 md:mb-[7px]">
                              {item.name}
                            </p>
                            {item.feedback}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <button className=" btn bg-[#FF754C] text-white h-[50px] flex justify-center items-center gap-1 md:gap-2 px-5 py-3 rounded-[10px] border border-white absolute z-10 -top-[25px] left-[50%] -translate-x-[50%]">
                    <LuDownload className="" />
                    <span className="text-[10px] md:text-[15px]">
                      {dbtInstalls}+ installs
                    </span>
                  </button>

                  <p className="info text-[10px] md:text-[12px] text-white absolute bottom-[10px] md:bottom-[15px] left-[60px] md:ml-[5%]">
                    Note: Above reviews are from VS Code Marketplace
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* hero */}
      </div>
    </div>
  );
};

export default DBTHeader;
