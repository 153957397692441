import CustomHeader from "../../components/customHeader";
import { SupportCards } from "./supportCards";

const Support = () => (
  <div style={{ background: "white" }}>
    <CustomHeader title="Support" heading="Home/Support" />

    <div className="lg:py-24 py-[60px] text-center">
      <div className="lexend text-[#082247] md:text-[42px] text-2xl font-bold mb-[10px]">
        Get in touch with us for issues or feature requests
      </div>
      <div className="font-lexend text-[20px] text-[#66768D]">
        Chat with us, join Slack channel in the dbt workspace, or just schedule
        a meeting with engineers
      </div>
    </div>
    <div className="mb-[40px] text-center">
      <div className="font-semibold text-[30px] text-[#082247]">
        Select your plan to view support options
      </div>
    </div>
    <div className="flex flex-col items-center justify-center pb-0 md:pb-[120px]">
      <SupportCards />
    </div>
  </div>
);

export default Support;
