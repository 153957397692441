import React from "react";

import bgSilq from "../../assets/images/pngs/silq_bg.png";

import quote from "../../assets/images/svgs/quote.svg";
import reviewsFrom from "../../assets/images/svgs/reviews_from.svg";
import reviewsFromMobile from "../../assets/images/svgs/reviews_from_mobile.svg";
import TestimonialSlider from "../testimonialSlider";

import testimonialTop from "../../assets/images/svgs/testimonial-top.svg";
import testimonialBottom from "../../assets/images/svgs/testimonial-bottom.svg";

import testimonialDots from "../../assets/images/svgs/testimonialDots.svg";

const Testimonial = () => {
  const [testimonialImg, setTestimonialImg] = React.useState(bgSilq);
  const isMobile = window.innerWidth <= 900;
  return (
    <div className="testimonial pt-[120px] pb-[28px] relative">
      <img
        src={testimonialTop}
        className="absolute top-0 left-0"
        alt="testimonial-top"
      />
      <img
        src={testimonialBottom}
        className="absolute bottom-0 right-0"
        alt="testimonial-bottom"
      />
      <div className="custom-container">
        <div className="lg:flex justify-between items-center gap-8">
          <div className="lg:w-5/12 relative">
            <img
              src={quote}
              className="absolute -top-[50px] right-[50px] z-20"
              alt="quote"
            />
            <img
              src={testimonialImg}
              className="w-full relative z-10"
              alt="testimonial"
            />
          </div>
          <div
            className={`lg:w-7/12 md:mt-0 mt-5 ${
              isMobile ? "text-center" : ""
            }`}
          >
            <p className="text-xl font-semibold text-[#247EFE]">Testimonial</p>
            <h2 className="lg:text-[42px] text-[24px] font-semibold text-[#082247]">
              What people say
            </h2>
            <div className="mt-[45px] relative">
              <div className="relative z-10">
                <TestimonialSlider
                  setTestimonialImg={setTestimonialImg}
                  isMobile={isMobile}
                />
              </div>
              <img
                src={testimonialDots}
                className="absolute bottom-[-85px] left-[-100px]"
                alt="testimonial-dots"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`md:mt-24 flex justify-center w-full`}>
        <img
          src={reviewsFrom}
          className="hidden md:block md:mt-[60px]"
          alt=""
        />
        <div className="md:hidden flex flex-col items-center mt-3">
          <div className="text-lg font-[500] mb-5 text-[#082247] z-10">
            Reviews from
          </div>
          <img src={reviewsFromMobile} className="z-10" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
