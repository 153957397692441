import { useEffect, useRef } from "react";
import CustomHeader from "../../components/customHeader";
import snowflakeVisibility from "../../assets/images/pngs/snowflake-visibility.png";
import snowflakeFixes from "../../assets/images/svgs/snowflake-page/snowflake-fixes.svg";
import snowflakeAnalytics from "../../assets/images/svgs/snowflake-page/snowflake-analytics.svg";
import snowflakeInsertion from "../../assets/images/svgs/snowflake-page/snowflake-insertion.svg";
import snowflakeWarehouseInsights from "../../assets/images/svgs/snowflake-page/snowflake-warehouse-inisghts.svg";
import snowflakeRecommendations from "../../assets/images/svgs/snowflake-page/snowflake-recommendation.svg";
import snowflakeAutoSavings from "../../assets/images/svgs/snowflake-page/snowflake-auto-savings.svg";
import { ProductDemoFooter } from "../../components/snowflake/productDemo";

const Snowflake = () => {
  const secLinking = useRef();
  useEffect(() => {
    secLinking.current = new MutationObserver(() => {
      const hash = window.location.hash.substring(1);
      console.log(hash);
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView();
        secLinking.current.disconnect();
      }
    });
    secLinking.current.observe(document, { childList: true, subtree: true });
    return () => {
      secLinking.current.disconnect();
    };
  }, []);

  return (
    <div style={{ background: "white" }}>
      <CustomHeader
        title={
          <div>
            Reduce{" "}
            <span className="font-[600] text-[#247EFE]">Snowflake Costs</span>{" "}
            by 30%
            <br />
            with DataPilot
          </div>
        }
        heading="Home / Snowflake"
      />
      <div
        id="warehouse-auto-savings"
        className="lg:p-12 lg:pb-0 py-[60px] text-left snowflake"
      >
        <div className="warehouse-insights">
          <div className="insights-text">
            <h1 className="lexend text-[#082247] md:text-[36px] text-[36px] font-semibold mb-[10px] text-center max-w-[1032px]">
              Automated Cost Savings via AI Agents
            </h1>
            <div className="lexend text-[#365178] text-[16px] font-[400] text-center max-w-[1032px]">
              The DataPilot platform comes with AI agents that can monitor your
              warehouse configurations and metrics like idle time, warehouse
              size, query execution time to take decisions about ideal warehouse
              configurations suited to reduce costs without much impact on the
              performance. AI agents will automatically make those changes for
              you 100s of times of day, so you can rake in the savings without
              investing any of your engineers time.
            </div>
          </div>
          <img src={snowflakeAutoSavings} alt="Snowflake Auto Saving" />
        </div>
      </div>
      <ProductDemoFooter />

      <div id="visibility" className="lg:p-24 lg:pb-12 py-[60px] snowflake">
        <div className="cost-savings">
          <div className="snowflake-text">
            <h1 className="lexend text-[#082247] md:text-[36px] text-[36px] font-semibold mb-[10px] text-center max-w-[1032px]">
              Get visibility into cost drivers &<br />
              potential savings
            </h1>
            <div className="lexend text-[#365178] text-[16px] font-[400] text-center max-w-[1032px]">
              Find out exactly what's costing you a lot of money in
              Snowflake—whether it's poorly written queries, tables that nobody
              is using but are still being kept up to date, or warehouse
              configurations that are bringing down warehouse utilization. The
              product will show you how much money you can save right from the
              start as you can see in the screenshot below!
            </div>
          </div>
          <img src={snowflakeVisibility} alt="Snowflake Visibility" />
        </div>
        <div className="snowflake-info-box">
          DataPilot will also enable you to control spending across different
          teams and it can also help
          <br /> you set guardrails against sudden spikes in costs.
        </div>
      </div>
      <ProductDemoFooter />

      <div
        id="query-insights"
        className="lg:py-24 py-[60px] text-left snowflake"
      >
        <div className="cost-savings">
          <h1 className="lexend text-[#082247] md:text-[36px] text-[36px] font-semibold text-center">
            Find Queries with High Costs and
            <br />
            Recommend Fixes
          </h1>
          <div className="fixes text-center">
            <div className="lexend text-[#365178] text-[16px] font-[400]">
              Altimate AI DataPilot calculates the cost of all queries,
              particularly those running at regular intervals that could be
              <br /> costing you thousands of $$. The screenshot below
              illustrates {/* TODO: DOLLAR SIGN */}
              how DataPilot was able to identify the single most
              <br /> expensive query on the system, executed every day and
              taking over an hour and a half to complete. Notice, that
              <br /> DataPilot doesn't just identify the query, but on the right
              you can see the list of potential issues as well.
            </div>
            <img src={snowflakeFixes} alt="Snowflake Fixes" />
            <div className="lexend text-[#365178] text-[16px] font-[400]">
              DataPilot uses a series of AI agents based on the Large Language
              Model (LLM) to identify issues and recommend
              <br /> solutions. it analyzes query metadata without accessing
              your actual data to identify key issues such as redundant
              <br /> filter conditions, exploding joins, or the selection of an
              incorrectly sized warehouse for query execution. DataPilot
              <br /> can precisely pinpoint the root cause of the problem, such
              as identifying the exact filter condition that's redundant
              <br /> within hundreds of lines of SQL query code. In many cases,
              DataPilot recommends the appropriate fix also using
              <br /> LLM-based AI agents.
            </div>
          </div>
          <div className="recommendations">
            <img
              src={snowflakeRecommendations}
              alt="Snowflake Recommendations"
            />
            <div className="lexend text-[#365178] text-[16px] font-[400]">
              That's a bit like trying to find a needle in a<br /> haystack,
              although even when you've identified the
              <br /> query, you've still got to identify the cause of the
              <br />
              performance issue. As you can see from the
              <br /> screenshot on the left, DataPilot's AI agent
              <br /> scanned over 700 lines of SQL to highlight the
              <br /> exact five lines of problematic code. Then, using
              <br /> the LLM, generated a series of recommendations
              <br /> on how to resolve it.
            </div>
          </div>
        </div>
      </div>
      <ProductDemoFooter />

      <div
        id="warehouse-insights"
        className="md:pt-24 md:pb-0 py-[60px] text-left snowflake"
      >
        <div className="warehouse-insights">
          <div className="insights-text">
            <h1 className="lexend text-[#082247] md:text-[36px] text-[36px] font-semibold mb-[10px] text-center max-w-[1032px]">
              Get insights on warehouse configurations and recommendations to
              lower costs
            </h1>
            <div className="lexend text-[#365178] text-[16px] font-[400] text-center max-w-[1032px]">
              As the workloads you run in Snowflake change dynamically, you need
              to change your warehouse configurations like size, scaling
              policies, node count as well as shift workloads to the right
              warehouses. Altimate AI gives you visibility into your warehouse
              configuration, workload distribution, and utilization with
              tracking of average consumption. Then, it uses AI to recommend
              warehouse configuration changes and workload adjustments, that can
              lead to significant cost savings. As you can see in the image
              below, there are many super fast in-expensive queries and cluster
              is underutilized by the insights. In that case, You can remove the
              clustering as well as you can reduce the size of warehouse.
              <br />
              <br />
              You can also set an alerts on consumption as well as different
              configurations, <br />
              so you can get notified in Slack / Email in case there are changes
              that you want to know about.
            </div>
          </div>
          <div className="mt-3">
            <img src={snowflakeWarehouseInsights} alt="Warehouse Insights" />
          </div>
        </div>
      </div>
      <ProductDemoFooter />

      <div
        id="table-insights"
        className="lg:py-12 py-[60px] text-left snowflake"
      >
        <div className="cost-savings">
          <h1 className="lexend text-[#082247] md:text-[36px] text-[36px] font-semibold mb-[10px] text-center">
            Find Unused Tables with High Data
            <br /> Insertion Costs
          </h1>
          <div className="analytics">
            <div className="lexend text-[#365178] text-[16px] font-[400] text-center">
              We all have more tables than we care to admit, and there are
              always tables sitting somewhere that nobody is
              <br /> using. However, these tables are kept up to date with
              scheduled pipelines. Altimate AI DataPilot quickly
              <br /> identifies such redundant tables and alerts admins. As
              you'll see from the screenshot below, it identified a<br /> total
              of $86,000 spent on queries writing to a table that has not been
              accessed in the past 60 days.
            </div>
            <img src={snowflakeInsertion} alt="Snowflake Insertions" />
            <div className="lexend text-[#365178] text-[16px] font-[400] text-center">
              Additionally, it uncovers unnecessary spending in clustering costs
              due to poorly configured cluster keys
              <br /> and accidental storage accumulations from time travel. As
              you can see in the screenshot below, the active
              <br /> storage for the table is 38 GB but time travel storage is
              3.61 TB and failsafe storage is a whopping 24 TB.
            </div>
            <img src={snowflakeAnalytics} alt="Snowflake Analytics" />
            <div className="snowflake-info-box">
              While these simple examples demonstrate the incredible potential
              cost savings from using DataPilot on your
              <br /> Snowflake system, it's just the tip of the iceberg. We find
              that DataPilot usually identifies a 30% savings in the
              <br /> Snowflake compute and storage costs. So, what are you
              waiting for? Click on the link below to schedule a demo
              <br /> or receive a recorded demo!
            </div>
          </div>
        </div>
      </div>
      <ProductDemoFooter />
    </div>
  );
};

export default Snowflake;
