import { useEffect, useState } from "react";
import slackIcon from "../../assets/images/svgs/supportCard/slack_support.svg";
import githubSupport from "../../assets/images/svgs/supportCard/github_support.svg";
import intercomSupport from "../../assets/images/svgs/supportCard/intercom_support.svg";
import trainingSupport from "../../assets/images/svgs/supportCard/training_support.svg";
import videoMeetSupport from "../../assets/images/svgs/supportCard/video_meet_support.svg";
import { Link } from "react-router-dom";
import { CustomDropdown } from "../../components/utils/utils";

const plans = ["Community", "Pro", "Team", "Enterprise"];

const NameToLogoMap = {
  Slack: slackIcon,
  Github: githubSupport,
  Chat: intercomSupport,
  "Video Meeting": videoMeetSupport,
  "Training services": trainingSupport,
};

const cardData = [
  {
    name: "Slack",
    mainMessage:
      "Get help from users and maintainers of “Power User for dbt” extension in the dbt Slack community!",
    footMessage: "Get Help from the Community",
    btnText: "Join",
    allowedPlans: ["Community", "Pro", "Team", "Enterprise"],
    buttonLink: "https://getdbt.slack.com/archives/C05KPDGRMDW",
  },
  {
    name: "Chat",
    mainMessage:
      "Prefer to just chat 1:1 about product issues? Please send us a message on the chatbot.",
    footMessage: "Message Us Instantly",
    btnText: "Chat",
    allowedPlans: ["Team", "Enterprise"],
    buttonLink: "https://app.myaltimate.com/contactus",
  },
  {
    name: "Github",
    mainMessage:
      "Interested in reporting a bug or suggesting a feature? Join Us on GitHub.",
    footMessage: "Create a Github Issue",
    btnText: "Create",
    allowedPlans: ["Community", "Pro", "Team", "Enterprise"],
    buttonLink: "https://github.com/AltimateAI/vscode-dbt-power-user/issues",
  },
  {
    name: "Video Meeting",
    mainMessage:
      "Real-time assistance and troubleshooting through live video support.",
    footMessage: "Message Us First on Chatbot",
    btnText: "Chat",
    allowedPlans: ["Enterprise"],
    buttonLink: "https://app.myaltimate.com/contactus",
  },
  {
    name: "Training services",
    mainMessage:
      "Expert-led training sessions to onboard your team to DataPilot platform",
    footMessage: "Message Us First on Chatbot",
    btnText: "Chat",
    allowedPlans: ["Enterprise"],
    buttonLink: "https://app.myaltimate.com/contactus",
  },
];

const PlanSelector = ({ currentPlan, setPlan }) => (
  <div className="h-[60px] bg-slate-50 border-0 flex flex-row max-w-[1250px]">
    {plans.map((plan) => (
      <div
        key={plan}
        onClick={() => setPlan(plan)}
        className={`cursor-pointer text-2xl font-medium left-[12px] flex flex-grow min-w-[250px] items-center px-3 ${
          plan === currentPlan
            ? "text-blue-500 border-b border-b-[5px] border-blue-500"
            : "text-sky-950"
        }`}
      >
        {plan}
      </div>
    ))}
  </div>
);

const MobilePlanSelector = ({ currentPlan, setPlan }) => (
  <CustomDropdown value={currentPlan} options={plans} onSelect={setPlan} />
);

const SupportCard = ({
  name,
  mainMessage,
  footMessage,
  btnText,
  buttonLink,
}) => (
  <div className="md:w-[400px] w-[90%] relative flex-col justify-start items-start inline-flex bg-white hover:bg-[#f5f8ff] rounded-[20px] border border-blue-500 px-[30px] py-[20px] justify-center items-center text-center">
    <img src={NameToLogoMap[name]} alt={NameToLogoMap[name]} />
    <div className="text-sky-950 text-2xl font-semibold font-['Lexend'] mt-[22px] mb-[15px]">
      {name}
    </div>
    <div className="w-[360px] h-[90px] text-slate-600 text-lg font-normal font-['Lexend'] leading-[28.80px]">
      {mainMessage}
    </div>
    <div className="text-sky-950 text-lg font-medium font-['Lexend'] leading-[27px] mb-[24px] mt-[28px]">
      {footMessage}
    </div>
    <Link to={buttonLink} target="_blank">
      <button className="px-[30px] py-3 bg-blue-500 hover:bg-[#004fbf] rounded-[10px] border justify-center items-center gap-2.5 inline-flex cursor-pointer">
        <div className="text-white text-lg font-medium font-['Lexend']">
          {btnText}
        </div>
      </button>
    </Link>
  </div>
);

export const SupportCards = () => {
  const [currentPlan, setCurrentPlan] = useState("Community");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="flex flex-col items-center justify-center gap-6 md:gap-[60px] md:px-0 px-[5%]">
      {isMobile ? (
        <MobilePlanSelector
          currentPlan={currentPlan}
          setPlan={setCurrentPlan}
        />
      ) : (
        <PlanSelector currentPlan={currentPlan} setPlan={setCurrentPlan} />
      )}
      <div
        className={`flex ${
          isMobile ? "flex-col" : "flex-wrap"
        } items-center justify-center gap-6 max-w-[1250px]`}
      >
        {cardData.map(
          (card) =>
            card.allowedPlans.includes(currentPlan) && (
              <SupportCard key={card.name} {...card} />
            ),
        )}
      </div>
    </div>
  );
};
