import React from "react";
import GetStarted from "../../components/getStarted";
import Columns from "../../components/columns";
import CustomHeader from "../../components/customHeader";

const Technology = () => {
  return (
    <div>
      <CustomHeader title={"Technology"} heading={"Home / Technology"} />
      <Columns />
      <GetStarted />
    </div>
  );
};

export default Technology;
