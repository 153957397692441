import data from "../../assets/images/svgs/datapilot/tech_stack/1-data.svg";
import dataMob from "../../assets/images/svgs/datapilot/tech_stack/1-data-mob.svg";
import lineage from "../../assets/images/svgs/datapilot/tech_stack/2-lineage.svg";
import lineageMob from "../../assets/images/svgs/datapilot/tech_stack/2-lineage-mob.svg";
import query from "../../assets/images/svgs/datapilot/tech_stack/3-query.svg";
import queryMob from "../../assets/images/svgs/datapilot/tech_stack/3-query-mob.svg";
import schema from "../../assets/images/svgs/datapilot/tech_stack/4-schema.svg";
import schemaMob from "../../assets/images/svgs/datapilot/tech_stack/4-schema-mob.svg";
import action1 from "../../assets/images/svgs/datapilot/action-1.svg";
import action2 from "../../assets/images/svgs/datapilot/action-2.svg";
import action3 from "../../assets/images/svgs/datapilot/action-3.svg";
import { NewsLetterForm } from "../footer";
import { useEffect, useState } from "react";

export const DatapilotTechStack = () => {
  const techStacksWeb = [data, lineage, query, schema];
  const techStacksMob = [dataMob, lineageMob, queryMob, schemaMob];
  const [currentTech, setCurrentTech] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTech((prevSVG) => (prevSVG + 1) % techStacksWeb.length);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const isMobile = window.innerWidth <= 900;
  const smallScreen = window.innerWidth > 900 && window.innerWidth <= 1500;
  return (
    <div className="data-pilot-agent">
      <div
        className={`text-[#082247] text-center font-semibold text-[${
          isMobile ? "20px" : "30px"
        }] md:text-[42px] mb-[${smallScreen ? "0px" : "40px"}]`}
      >
        Accelerate every stage of data development in your data stack
      </div>
      <img
        className="md:h-[750px] h-auto"
        src={isMobile ? techStacksMob[currentTech] : techStacksWeb[currentTech]}
        alt="data-pilot-agents"
      />
    </div>
  );
};

export const DatapilotActions = () => (
  <div className="data-pilot-actions items-center">
    <div className="text-[#082247] text-center font-semibold text-[30px] md:text-[42px] mb-5">
      DataPilot in Action
    </div>
    <div className="text-center">
      <div>
        From Unit Testing to Data Science, building Math Libraries to Python
        Memoization
      </div>
      <div>Elevate every aspect of your coding with Codeium.</div>
    </div>
    <div className="data-pilot-action-imgs">
      <img src={action1} alt="Action 1" />
      <img src={action2} alt="Action 2" />
      <img src={action3} alt="Action 3" />
    </div>
    <div className="upcoming-features">
      <div className="inner-text">
        <div className="text-[25px] font-semibold">
          Learn About our Upcoming Features
        </div>
        <div className="text-[25px] font-semibold">
          Sign-up for the Newsletter
        </div>
      </div>
      <NewsLetterForm />
    </div>
  </div>
);
