import React from "react";
import { FaCheckCircle } from "react-icons/fa";

// import col1Img from "../../assets/images/svgs/col-1.svg";
// import col2Img from "../../assets/images/svgs/col-2.svg";
// import col3Img from "../../assets/images/svgs/col-3.svg";
// import col4Img from "../../assets/images/svgs/col-4.svg";

// import robot1 from "../../assets/images/svgs/robot1.svg";
// import robot2 from "../../assets/images/svgs/robot2.svg";
// import robot3 from "../../assets/images/svgs/robot3.svg";
// import robot4 from "../../assets/images/svgs/robot4.svg";

import robot1Json from "../../assets/animations/lottie/robot1";
import robot2Json from "../../assets/animations/lottie/robot2";
import robot3Json from "../../assets/animations/lottie/robot3";
import robot4Json from "../../assets/animations/lottie/robot4";

// import robotAnimation from "../../assets/animations/robot-animation-1.mp4"

import dots from "../../assets/images/svgs/col-dots.svg";
import Lottie from "react-lottie";

const Columns = () => {
  const textData = [
    "Intelligence layer to retrieve selective context",
    "Ability to execute in your environment",
  ];

  const textData2 = [
    "Human In the Loop before executing key steps",
    "Ability to provide feedback to AI to course correct",
  ];
  const textData3 = [
    "Automatic recommendation of guardrails based on metadata",
    "Enforcement of guardrails in your data sources",
  ];
  const textData4 = [
    "Agent-based workflows with dynamic tasks for various use cases",
    "No need to worry about the prompts to LLM models",
  ];

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: robot1Json,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: robot2Json,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions3 = {
    loop: true,
    autoplay: true,
    animationData: robot3Json,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions4 = {
    loop: true,
    autoplay: true,
    animationData: robot4Json,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="columns">
      <div className="left relative md:py-[100px] pt-[50px]">
        <div className="custom-container">
          <div className="flex lg:flex-row flex-col justify-between items-center relative z-10">
            <div className="lg:w-1/2 w-full md:pr-14 relative">
              <img
                src={dots}
                alt="dots"
                className="absolute -top-[150px] right-0"
              />
              <h2 className="md:text-[40px] text-[24px] font-bold">
                DataPilot <br className="md:block hidden" /> connects to
                your <br className="md:block hidden" /> data sources
              </h2>

              <div className="mt-[40px]">
                {textData.map((text, index) => (
                  <p
                    key={index}
                    className="text-[18px] text-[#082247] font-normal mb-[20px] flex justify-start items-start border border-[#dadee3ca] rounded-[5px] p-[20px]"
                  >
                    <div className="min-w-[13px] mr-1">
                      <FaCheckCircle className="text-[#2196F3] mr-2 mt-1" />{" "}
                    </div>
                    {text}
                  </p>
                ))}
              </div>
            </div>

            <div className="lg:w-1/2 w-full relative md:pl-14 text-right">
              {/* <img
                src={robot1}
                alt="robot1"
                className="absolute -top-[20px] right-0"
              />
              <img src={col1Img} alt="col1" className="w-full" /> */}

              <Lottie options={defaultOptions1} />

              {/* <video className="w-full" autoPlay loop muted>
                <source src={robotAnimation} type="video/mp4" />
              </video> */}
            </div>
          </div>
        </div>
      </div>

      <div className="right relative md:py-[100px] pt-[50px]">
        <div className="custom-container">
          <div className="flex lg:flex-row flex-col-reverse justify-between items-center relative z-10">
            <div className="lg:w-1/2 w-full relative md:pr-14 text-right">
              {/* <img
                src={robot2}
                alt="robot1"
                className="absolute bottom-[20px] right-0"
              />
              <img src={col2Img} alt="col1" className="w-full" /> */}
              <Lottie options={defaultOptions2} />
              {/* <video className="w-full" autoPlay loop muted>
                <source src={robotAnimation} type="video/mp4" />
              </video> */}
            </div>
            <div className="lg:w-1/2 w-full md:pl-14 relative">
              <img
                src={dots}
                alt="dots"
                className="absolute -top-[150px] left-0"
              />
              <h2 className="md:text-[40px] text-[24px] font-bold">
                Complete control with <br className="md:block hidden" />{" "}
                approvals in the <br className="md:block hidden" /> workflows
              </h2>

              <div className="mt-[40px]">
                {textData2.map((text, index) => (
                  <p
                    key={index}
                    className="text-[18px] text-[#082247] font-normal mb-[20px] flex justify-start items-start border border-[#dadee3ca] rounded-[5px] p-[20px]"
                  >
                    <div className="min-w-[13px] mr-1">
                      <FaCheckCircle className="text-[#2196F3] mr-2 mt-1" />{" "}
                    </div>
                    {text}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left relative md:py-[100px] pt-[50px]">
        <div className="custom-container">
          <div className="flex lg:flex-row flex-col justify-between items-center relative z-10">
            <div className="lg:w-1/2 w-full md:pr-14 relative">
              <img
                src={dots}
                alt="dots"
                className="absolute -top-[150px] right-0"
              />
              <h2 className="md:text-[40px] text-[24px] font-bold">
                LLM agents tuned <br className="md:block hidden" /> specially
                for data <br className="md:block hidden" /> use cases
              </h2>

              <div className="mt-[40px]">
                {textData4.map((text, index) => (
                  <p
                    key={index}
                    className="text-[18px] text-[#082247] font-normal mb-[20px] flex justify-start items-start border border-[#dadee3ca] rounded-[5px] p-[20px]"
                  >
                    <div className="min-w-[13px] mr-1">
                      <FaCheckCircle className="text-[#2196F3] mr-2 mt-1" />{" "}
                    </div>
                    {text}
                  </p>
                ))}
              </div>
            </div>

            <div className="lg:w-1/2 w-full relative md:pl-14 text-right">
              {/* <img
                src={robot1}
                alt="robot1"
                className="absolute -top-[20px] right-0"
              />
              <img src={col1Img} alt="col1" className="w-full" /> */}

              <Lottie options={defaultOptions4} />

              {/* <video className="w-full" autoPlay loop muted>
                <source src={robotAnimation} type="video/mp4" />
              </video> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Columns;
