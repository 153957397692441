import React, { useState, useEffect } from "react";
import Header from "../header";

const CustomHeader = ({ heading, title }) => {
  return (
    <div className="custom-header">
      <div className="custom-container">
        <Header />

        <div className="lg:pt-[62px] lg:pb-[80px] py-[60px] text-center">
          <div className="mt-[48px] md:mt-[100px]">
            <p className="text-[#365178] text-center font-lexend font-normal">
              {heading}
            </p>
            <h1 className="text-[#082247] text-[42px] font-semibold">
              {title}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomHeader;
