import React from "react";

import sec1 from "../../assets/images/svgs/sec1.svg";
import sec2 from "../../assets/images/svgs/sec2.svg";
import sec3 from "../../assets/images/svgs/sec3.svg";

const SecurityApproach = () => {
  return (
    <div className="text-center px-3 py-14 md:text-left md:pl-[195px] md:pr-[80px] md:py-[122px] bg-[#F5F7F9] flex flex-col md:flex-row justify-between">
      <div className="flex flex-col items-center md:items-left justify-between mb-12 md:mb-0">
        <div>
          <div className="font-semibold text-[#082247] text-[30px] md:text-[42px] leading-normal">
            Privacy and Security First Approach
          </div>
          <p className="mt-3 px-3 md:px-0 md:mt-9 font-normal text-[#365178] text-[14px] md:text-[16px]">
            Altimate AI is committed to privacy and security of our customer
            environments. The solution works by using only metadata from
            customer environment, and none of customer data is used for training
            the LLMs.
          </p>
        </div>
        <div className="w-full">
          <img className="hidden md:block mt-16" src={sec2} alt="" />
        </div>
        <img className="md:hidden mt-16" src={sec3} alt="" />
      </div>
      <img src={sec1} alt="" />
    </div>
  );
};

export default SecurityApproach;
