import React from "react";

import story1 from "../../assets/images/jpgs/story1.jpg";
import story2 from "../../assets/images/jpgs/our_story.jpg";

const OurStory = () => {
  return (
    <div className="our-story md:pt-[100px] pt-[50px] bg-white">
      <div className="custom-container">
        <div className="lg:flex justify-between items-start gap-10">
          <div className="lg:w-2/3">
            <p className="text-xl font-[600] text-[#247EFE] mb-[10px]">
              Our Story
            </p>
            <h2 className="lg:text-[42px] text-[24px] font-[600] lh-2 text-[#082247">
              We dream about the perfect data world, and we are on a quest to
              make it a reality.
            </h2>

            <img src={story1} className="w-full mt-[40px]" alt="" />
          </div>

          <div className="lg:w-1/3 flex lg:flex-col flex-col-reverse pt-10">
            <img
              src={story2}
              className="w-full lg:mb-[30px] lg:mt-0 mt-[30px]"
              alt=""
            />

            <p className="text-[#8390A3]">
              We are builders who have created category-defining data and AI
              products that are loved by thousands of companies worldwide. We
              tinker with cutting-edge technologies and many times even just
              invent something completely new and amazing.
              <br />
              <br />
              We are based out of the San Francisco Bay area and backed by some of
              prominent advisors and investors in the enterprise space.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
