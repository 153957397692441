import React from "react";
import FAQDropdown from "../faqDropdown";
import { Link } from "react-router-dom";

const FAQ = () => {
  const data = [
    {
      question: "What data do you use?",
      answer:
        "We do not use actual data for any of the Altimate AI features. We use only metadata e.g. table names, schemas",
    },
    {
      question: "Do you use customer data / metadata to train LLMs?",
      answer: "No, we do not train LLMs using customer data or metadata.",
    },
    {
      question: "What’s the quality of Gen AI features?",
      answer:
        "Gen AI is a remarkable technology, consistently offering directionally correct answers. However, like any advanced tool, there may be occasions where it requires a touch of refinement. We genuinely appreciate your feedback through our in-product widgets, as it guides our engineers in enhancing the experience for everyone.",
    },
  ];

  return (
    <div className="px-6 py-[60px] md:px-[345px] md:py-[120px] bg-[#F5F7F9] flex flex-col items-center text-center">
      <div className="w-full md:w-[750px]">
        <div className="font-semibold text-[#082247] text-[32px] md:text-[42px] leading-normal">
          Frequently Asked Questions
        </div>
        <div className="mt-12 flex flex-col gap-[30px]">
          {data.map((d, i) => {
            return <FAQDropdown question={d.question} answer={d.answer} />;
          })}
        </div>
        <div className="hidden md:block mt-[30px] font-medium text-[18px] text-[#5E7493]">
          {"Can't find your answer here? Check the FAQ in the "}
          <Link
            to="https://docs.myaltimate.com/arch/faq/"
            className="text-[#3F8CFF] cursor-pointer"
            target="_blank"
          >
            Documentation
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
