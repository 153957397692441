import React from "react";
import Hero from "../../components/breadcrumbHero";
import GetStarted from "../../components/getStarted";
import Team from "../../components/team";
import Partners from "../../components/partners";
import ProudOf from "../../components/proudOf";
import OurStory from "../../components/ourStory";
import PhilosophyCulture from "../../components/philosophyCulture";
import CustomHeader from "../../components/customHeader";

const About = () => {
  return (
    <div className="about-page">
      <CustomHeader heading={"Home / About us"} title={"About Us"} />
      <OurStory />
      <ProudOf />
      <PhilosophyCulture />
      <Partners />
      <Team />
      <GetStarted />
    </div>
  );
};

export default About;
