import CustomHeader from "../../components/customHeader";
import { UserCompanies } from "../../components/companies";
import PricingComponent from "./pricingCard_2";

const Pricing = () => (
  <div style={{ background: "white" }}>
    <CustomHeader title="Pricing" heading="Home/Pricing" />

    <div className="lg:py-[48px] py-[40px] text-center">
      <h1 className="lexend text-[#082247] md:text-[30px] text-4xl font-bold mb-[24px]">
        Easily Accelerate Your Work. Start for Free.
      </h1>
      <h3 className="font-lexend md:text-lg text-[20px] text-[#66768D]">
        Access DataPilot Features. No Credit Card Required.
      </h3>
    </div>
    <PricingComponent />
    <div className="flex flex-col items-center text-center mb-[50px] font-medium text-[18px] text-[#5E7493] sm:block">
      {"If you need additional info, please check the "}
      <a
        href="https://docs.myaltimate.com/arch/pricingfaq/"
        className="text-[#3F8CFF] cursor-pointer"
      >
        Pricing FAQ
      </a>
    </div>
    <UserCompanies />
    {/* <PricingFAQ /> */}
  </div>
);

export default Pricing;
