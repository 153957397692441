import { GET_STARTED_GOOGLE_FORM } from "../../constant";
import getSTarted from "../../assets/images/svgs/get_started.svg";

export const ProductDemoFooter = () => (
  <div
    className="product-demo"
    style={{
      backgroundImage: `url(${getSTarted})`,
    }}
  >
    <div className="heading-content">
      <div className="heading">Interested in a Recorded Demo?</div>
      <div className="content">
        <p>
          Please share your email address to receive a recorded demo or
          <br />
          to schedule a live demo with one of our Snowflake experts.
        </p>
      </div>
    </div>
    <button>
      <a href={GET_STARTED_GOOGLE_FORM} target="_blank" rel="noreferrer">
        Get Product Demo
      </a>
    </button>
  </div>
);
