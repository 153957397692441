import React from "react";
import file from "../../dbt_assets/images/file.svg";
import youtube from "../../dbt_assets/images/youtube.svg";
import github from "../../dbt_assets/images/github.svg";
import slack from "../../dbt_assets/images/slack.svg";
import { Link } from "react-router-dom";

const Resources = () => {
  const isMobile = window.innerWidth <= 900;
  const handleGithubLinkClick = () => {
    if (window.gtag) {
      window.gtag("config", "G-B7LCCXF90E");
      window.gtag("event", "conversion", {
        send_to: "AW-10951864387/Gj_uCJzPgc8YEMPgoOYo",
      });
    }
  };

  return (
    <div
      className="bg-[#082247] md:pb-[110px] pb-[60px] pt-[60px] md:pt-[100px]"
      id="resources"
    >
      <div className="custom-container">
        <div className="text-center">
          <h2 className="text-white text-[32px] md:text-[40px] text-2xl font-bold mb-[48px]">
            Resources
          </h2>

          <div className="cards p-[33px] md:p-0 grid lg:grid-cols-2 grid-cols-1 gap-[30px]">
            <div className="card bg-white p-[24px] rounded-[18px]">
              <div className="flex flex-col items-center md:items-start gap-[24px]">
                <div className="flex flex-col md:flex-row justify-start items-center gap-[10px]">
                  <img src={file} alt="" />
                  <div className="hidden md:block text-left text-[24px] text-[#082247] font-[500]">
                    Documentation
                  </div>
                  <div className="md:hidden text-center whitespace-nowrap mt-2 mb-4 text-xl text-[#082247] font-[500]">
                    Documentation
                  </div>
                </div>

                <div className="flex flex-col md:flex-row justify-between items-center gap-[10px] w-full">
                  <Link
                    to="https://datapilot.readthedocs.io/en/latest/introduction.html"
                    target="_blank"
                    className={`bg-[#3f8cff1a] hover:bg-[#3F8CFF] text-[#3f8cff] hover:text-white rounded-full px-[24px] py-[8px] ${
                      isMobile && "w-full"
                    }`}
                  >
                    Python Package
                  </Link>
                  <Link
                    to="https://docs.myaltimate.com/"
                    target="_blank"
                    className={`bg-[#3F8CFF] hover:bg-[#3677D9] text-white rounded-full px-[24px] py-[8px] ${
                      isMobile && "w-full"
                    }`}
                  >
                    Power User for dbt
                  </Link>
                </div>
              </div>
            </div>
            <div className="card bg-white p-[24px] rounded-[18px]">
              <div className="flex flex-col items-center md:items-start md:gap-[25px] gap-[20px]">
                <div className="flex flex-col md:flex-row md:mb-0  justify-start items-center md:gap-[10px] gap-[20px]">
                  <img src={youtube} alt="" />
                  <span className="text-xl text-[#082247] md:text-[24px] font-[500]">
                    Tutorials
                  </span>
                </div>

                <div className="w-full flex flex-col md:flex-row justify-between items-center">
                  {!isMobile && (
                    <div className="text-[#082247] text-[20px] font-[500]">
                      Learn and Practice
                    </div>
                  )}
                  <Link
                    to={"https://www.altimate.ai/videos"}
                    target="_blank"
                    className={`bg-[#3F8CFF] hover:bg-[#3677D9] text-white rounded-full px-[24px] py-[8px] ${
                      isMobile && "w-full"
                    }`}
                  >
                    Open
                  </Link>
                </div>
              </div>
            </div>
            <div className="card bg-white p-[24px] rounded-[18px]">
              <img
                src={github}
                className="mb-[28px] block md:mr-auto md:ml-0 mx-auto"
                alt=""
              />
              <p className="text-lg text-[#365178] text-center md:text-left md:mb-[130px] mb-[20px]">
                Interested in contributing or reporting a bug? Join Us on
                GitHub.
              </p>
              <div className="flex flex-col md:flex-row justify-between items-center gap-[10px] w-full">
                <Link
                  to="https://github.com/AltimateAI/datapilot-cli"
                  target="_blank"
                  className={`bg-[#3f8cff1a] hover:bg-[#3F8CFF] text-[#3f8cff] hover:text-white rounded-full px-[24px] py-[8px] ${
                    isMobile && "w-full"
                  }`}
                >
                  Python Package
                </Link>
                <Link
                  to="https://github.com/AltimateAI/vscode-dbt-power-user"
                  target="_blank"
                  className={`bg-[#3F8CFF] hover:bg-[#3677D9] text-white rounded-full px-[24px] py-[8px] ${
                    isMobile && "w-full"
                  }`}
                >
                  Power User for dbt
                </Link>
              </div>
            </div>
            <div className="card bg-white p-[24px] rounded-[18px] h-full">
              <div className="flex flex flex-col md:flex-row justify-start items-center gap-[24px] mb-[28px]">
                <img src={slack} alt="" />
                <p className="text-[24px] text-[#082247] font-[500]">
                  Slack channel in dbt workspace
                </p>
              </div>
              <p className="text-lg text-[#365178] md:text-left md:mb-[130px] mb-[20px]">
                Get help from users in the “tools-dbt-power-user” channel in the
                dbt Slack community!
              </p>
              <div className="flex flex-col md:flex-row justify-between items-center">
                <div className="flex justify-start items-center mb-4 md:mb-0 gap-[10px]">
                  <span className="text-xl text-[#082247] font-[500]">
                    Connect and Collaborate
                  </span>
                </div>

                <Link
                  to={"https://getdbt.slack.com/archives/C05KPDGRMDW"}
                  target="_blank"
                  className={`bg-[#3F8CFF] hover:bg-[#3677D9] text-white rounded-full px-[24px] py-[8px] ${
                    isMobile && "w-full"
                  }`}
                >
                  Join
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
