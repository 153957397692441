import React from "react";
import GetStarted from "../../components/getStarted";
import CustomHeader from "../../components/customHeader";
import Tutorials from "../../components/tutorials";

const Videos = () => {
  return (
    <div>
      <CustomHeader title={"DataPilot Tutorials"} heading={"Home / Videos"} />
      <Tutorials />
      <GetStarted />
    </div>
  );
};

export default Videos;
