import React, { useState } from "react";
import { Link } from "react-router-dom";

// import ss1 from "../../dbt_assets/images/visualize-result-set.gif";
// import ss2 from "../../dbt_assets/images/2.gif";
// import ss5 from "../../dbt_assets/images/3.gif";
// import ss4 from "../../dbt_assets/images/4.gif";

import demo_1_1 from "../../assets/images/gifs/extension_gifs/1-1-sql-to-dbt-models.gif";
import demo_1_2 from "../../assets/images/gifs/extension_gifs/1-2-update-dbt-mode.gif";
import demo_2_1a2 from "../../assets/images/gifs/extension_gifs/2-1_2-autocomplete.gif";
import demo_2_3 from "../../assets/images/gifs/extension_gifs/2-3-runmodeltests.gif";
import demo_3_1 from "../../assets/images/gifs/extension_gifs/3-1-generate_tests.gif";
import demo_3_2 from "../../assets/images/gifs/extension_gifs/3-2-query_explanation.gif";
import demo_3_3 from "../../assets/images/gifs/extension_gifs/3-3-lineage.gif";
import demo_4_1 from "../../assets/images/gifs/extension_gifs/4-1-gendoc.gif";
import demo_4_2 from "../../assets/images/gifs/extension_gifs/4-2_3-previewquery.gif";
import demo_5_1 from "../../assets/images/gifs/extension_gifs/5-1-sqlValidation.gif";
import demo_5_2a3 from "../../assets/images/gifs/extension_gifs/5-2_3-project_health_check.gif";

import checkMark from "../../assets/images/svgs/check-mark.svg";

// create a component for the div we are rendering while looping through the dbtFeatureColumns array
const DBTFeaturesColumns = ({ id, reversed, title, points }) => {
  const [showGif, setShowGif] = useState(0);
  return (
    <div
      id={id}
      className={`w-full h-full flex md:flex-row flex-col items-center justify-center md:mt-[140px] md:gap-[60px] ${
        reversed ? "md:flex-row-reverse" : "md:flex-row"
      }`}
    >
      <div
        className={`md:py-[70px] md:h-auto md:min-w-[520px] md:max-w-[521px] md:text-left text-center`}
      >
        <h2 className="lexend text-[#082247] md md:text-[30px] text-2xl font-bold md:mt-0 mt-[44px] mb-[10px] leading-[39px]">
          {title}
        </h2>
        <div className="flex flex-col mt-10">
          {points.map((point, idx) => (
            <div
              key={idx}
              onClick={(e) => {
                e.stopPropagation();
                setShowGif(idx);
              }}
              className={`hover:bg-[#FFFFFF] min-h-[58px] flex justify-start items-start border border-[#dadee3ca] rounded-[10px] p-[16px] mb-5 cursor-pointer gap-[8px] ${
                idx === showGif && "bg-[#FFFFFF]"
              }`}
            >
              <img src={checkMark} alt="check-mark" className="mt-1" />
              <div className="w-full text-[#082247] text-base text-left font-light leading-[25.6px]">
                {point.text}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-[382px] max-h-[250px] md:min-w-[44%] md:max-h-[100%] flex flex-col items-center md:justify-center justify-start">
        <img
          className="max-h-[250px] md:w-full md:max-h-full border rounded-[10px]"
          src={points[showGif].gifSrc}
          alt="Demo GIF"
        />
      </div>
    </div>
  );
};

const dbtFeatureColumns = [
  {
    id: "col1",
    title: "Generate dbt models and translate SQL",
    points: [
      {
        text: "Generate dbt Models from SQL or source files",
        gifSrc: demo_1_1,
      },
      { text: "Update dbt model in natural language", gifSrc: demo_1_2 },
      {
        text: "Translate queries in one SQL dialect to another.",
        gifSrc: demo_1_2,
      },
    ],
    reversed: false,
  },
  {
    id: "col2",
    title: "Quickly navigate dbt code and smart-complete",
    points: [
      {
        text: "Smart-complete model names, macros, sources and docs.",
        gifSrc: demo_2_1a2,
      },
      {
        text: "Click on model names, macros, sources to go to definitions.",
        gifSrc: demo_2_1a2,
      },
      {
        text: "Click to build or run upstream/downstream models, tests.",
        gifSrc: demo_2_3,
      },
    ],
    reversed: true,
  },
  {
    id: "col3",
    title: "Debug with column lineage, generated tests",
    points: [
      { text: "Generate tests and tags.", gifSrc: demo_3_1 },
      {
        text: "Get query explanations in conversational chat.",
        gifSrc: demo_3_2,
      },
      {
        text: "Impact analysis with real time column lineage.",
        gifSrc: demo_3_3,
      },
    ],
    reversed: false,
  },
  {
    id: "col4",
    title: "Collaborate via docs generation, share query results",
    points: [
      { text: "(Bulk) generate dbt docs.", gifSrc: demo_4_1 },
      { text: "Preview query results and analyze.", gifSrc: demo_4_2 },
      {
        text: "Share query results as a webpage for reviews.",
        gifSrc: demo_4_2,
      },
    ],
    reversed: true,
    gifSrc: demo_1_1,
  },
  {
    id: "col5",
    title: "Follow best practices via health checks",
    points: [
      { text: "Check SQL issues before execution.", gifSrc: demo_5_1 },
      {
        text: "Perform checks for dbt best practices and common issues.",
        gifSrc: demo_5_2a3,
      },
      { text: "Embed checks in IDE, Git, CI/CD.", gifSrc: demo_5_2a3 },
    ],
    reversed: false,
    gifSrc: demo_1_1,
  },
];

const DBTColumns = () => {
  const handleGithubLinkClick = () => {
    if (window.gtag) {
      window.gtag("config", "G-B7LCCXF90E");
      window.gtag("event", "conversion", {
        send_to: "AW-10951864387/Gj_uCJzPgc8YEMPgoOYo",
      });
    }
  };
  return (
    <div className="bg-[#F5F7F9] md:columns md:pb-[100px] pb-[70px]">
      <div className="text-[#082247] font-lexend text-4xl font-semibold text-center mt-[100px] md:mb-[30px]">
        Extension Features
      </div>
      {dbtFeatureColumns.map((feature, index) => (
        <DBTFeaturesColumns key={index} {...feature} />
      ))}
    </div>
  );
};

export default DBTColumns;
